import React, { Component } from "react";
import "./signin.css";
import "./main.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";

class reset extends Component {
  state = { test_: true };
  trial = () => {
    this.setState({ test_: !this.state.test_ });
  };

  render() {
    let show = this.state.test_ ? "password" : "text";
    let eye = this.state.test_ ? <Visibility /> : <VisibilityOff />;
    return (
      <React.Fragment>
        <div className="maincontainer">
          <p className="title">Reset password</p>
          <p className="instruct">Please enter a new password below</p>

          <form className="form_content">
            {" "}
            <div className="formwidth">
              {" "}
              <input
                type={show}
                placeholder="Enter a new password..."
                className="form_input"
              />
              <div className="onoff" onClick={() => this.trial()}>
                {eye}
              </div>
            </div>
            <div className="formwidth">
              {" "}
              <input
                type={show}
                placeholder="Confirm password..."
                className="form_input"
              />
              <div className="onoff" onClick={() => this.trial()}>
                {eye}
              </div>
            </div>
            <input
              type="button"
              value="Reset Password"
              className="submitbutton"
            />
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default reset;
