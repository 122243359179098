import React, { Component } from 'react';
import {Switch, BrowserRouter as Router, Route} from 'react-router-dom';
import Forgot from "./component/forgotPassword.jsx";
import Reset from "./component/resetPassword.jsx";
import Signin from "./component/signinUser";
import Welcome from "./component/welcome.jsx";
import Home from "./component/home.jsx";
import Signup from "./component/signUp.jsx";
import Task from "./component/task.jsx";


function App() {
  return(
    <Router>
      <Switch>
     
          <Route exact path="/" component={Welcome}/>
          <Route  path="/Signin" component={Signin}/>
          <Route  path="/Forgot" component={Forgot}/>
          <Route  path="/Signin"  component={Signin}/>
          <Route  path="/Signup"  component={Signup}/>
          <Route  path="/Reset"  component={Reset}/>
          <Route  path="/Home"  component={Home}/>
          <Route  path="/Task"  component={Task}/>

     </Switch>
    </Router>
  );
}

export default App;