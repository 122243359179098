import React, { Component } from "react";
import "./main.css";
import { Link } from "react-router-dom";
import Edu from "./images/education.png";

class Welcome extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="welcome_container">
          <div className="mar_holder">
            {" "}
            <Link to="/Signin">
              {" "}
              <div className="sign_in">Sign In</div>
            </Link>
          </div>

          <div className="sign_text">
            <div>
              <p className="design1">WELCOME TO</p>
              <p className="design2">i.ebbing</p>
              <p className="design3">Creating an endless ripple</p>
              <Link to="/Signup">
                <div className="sign_up">Sign up</div>
              </Link>
            </div>

            <img src={Edu} className="edu_img" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Welcome;
