import React, { Component } from "react";
import "./main.css";
import Logo from "./images/logo.png";
import Google from "./images/google.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Forgot from "./forgotPassword.jsx";
import { Link } from "react-router-dom";
import Wlc from "./images/welcomepic.png";
class Signin extends Component {
  state = {
    test_: true,
  };

  trial = () => {
    this.setState({ test_: !this.state.test_ });
  };

  render() {
    let show = this.state.test_ ? "password" : "text";
    let eye = this.state.test_ ? <Visibility /> : <VisibilityOff />;
    return (
      <React.Fragment>
        <div className="main_container">
          <div className="side_image">
            <div className="logo_cont">
              {" "}
              <img src={Logo} className="logo" />
            </div>
            <div className="welcome">
              <p>HELLO...</p>
              <p>Welcome</p>
              <p>Back!!!</p>
              <img src={Wlc} className="wlc_img" />
            </div>
          </div>
          <div className="side">
            <p className="title">Sign In</p>
            <p className="instruct">Login to have an amazing experience</p>
            <form className="form_content">
              {" "}
              <input
                type="Email"
                placeholder="Email..."
                className="form_input"
              />
              <div className="formwidth">
                {" "}
                <input
                  type={show}
                  placeholder="Password..."
                  className="form_input"
                />
                <div className="onoff" onClick={() => this.trial()}>
                  {eye}
                </div>
              </div>
              <div>
                {" "}
                <input type="checkbox" placeholder="Password" />
                <label for="Password" className="pass">
                  Remember Password
                </label>
              </div>
              <div className="holder">
                <Link to="/Forgot">Forgot Password? </Link>
                <input type="button" value="Sign Up" className="submit" />
              </div>
              <div className="showbreak">
                <div className="break"> </div>
                <p>or</p>
                <div className="break"></div>
              </div>
              <div className="extra">
                <div className="gcont">
                  <div className="gcontain">
                    <img src={Google} className="google" />
                    <b>Sign In with Google</b>
                  </div>
                </div>
                <div className="gcont">
                  <p>
                    Don't have an account? <Link to="Signup">Sign up</Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Signin;
