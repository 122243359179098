import React, { Component } from "react";
import "./main.css";
import "./signin.css";
import Img from "./images/defaultImg.jpg";
import {
  Camera,
  CameraAlt,
  CameraOutdoor,
  Photo,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

class Signup extends Component {
  state = {
    test_: true,
    value: "",
    place: true,
  };

  trial = () => {
    this.setState({ test_: !this.state.test_ });
  };

  mainButton = () => {
    document.getElementById("Attachment").click();
  };

  handleChange = (event) => {
    this.setState({ value: URL.createObjectURL(event.target.files[0]) });
  };
  changePlace = () => {
    this.setState({ place: false });
  };
  render() {
    let show = this.state.test_ ? "password" : "text";
    let eye = this.state.test_ ? <Visibility /> : <VisibilityOff />;
    let imgPreview = this.state.value ? (
      <img src={this.state.value} alt="" className="p_img" />
    ) : (
      <img src={Img} alt="" className="p_img" />
    );

    let dateplace = this.state.place ? "text" : "date";

    return (
      <React.Fragment>
        <div className="maincontainer">
          <p className="title">Sign Up</p>
          <form className="main-form">
            <div className="gg">
              {" "}
              <div className="sign_form">
                <input
                  type="text"
                  placeholder="Full Name"
                  className="form_input"
                />
                <input
                  type="text"
                  placeholder="Username"
                  className="form_input"
                />
                <input
                  type="Email"
                  placeholder="Email..."
                  className="form_input"
                />
                <div className="formwidth">
                  {" "}
                  <input
                    type={show}
                    placeholder="Password..."
                    className="form_input"
                  />
                  <div className="onoff" onClick={() => this.trial()}>
                    {eye}
                  </div>
                </div>
                <div className="formwidth">
                  {" "}
                  <input
                    type={show}
                    placeholder="Confirm Password..."
                    className="form_input"
                  />
                  <div className="onoff" onClick={() => this.trial()}>
                    {eye}
                  </div>
                </div>
                <div>
                  {" "}
                  <input type="checkbox" id="agree" />
                  <label for="agreed" id="agree">
                    I have read & agreed to the terms, conditions and privacy
                    policy
                  </label>
                </div>
              </div>
              <div className="sign_form">
                <input
                  type="number"
                  placeholder="Phone Number"
                  className="form_input"
                />
                <input
                  type={dateplace}
                  placeholder="Date Of Birth"
                  className="form_input"
                  required="required"
                  onFocus={() => {
                    this.changePlace();
                  }}
                  title="Date of birth"
                />
                <select id="Gender" name="Gender" className="form_input">
                  <option value="" disabled selected hidden>
                    Select Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <div className=" forflex">
                  <div className="uploaddiv">
                    {imgPreview}
                    <div
                      className="upload"
                      onClick={() => {
                        this.mainButton();
                      }}
                      title="Upload profile picture"
                    >
                      {" "}
                      <CameraAlt style={{ fontSize: 30 }} />
                    </div>
                    <input
                      type="file"
                      className="reference"
                      id="Attachment"
                      onChange={this.handleChange}
                    />
                  </div>{" "}
                </div>
              </div>
            </div>
            <Link to="./Home" className="submitbuttonup">
              {" "}
              Sign Up{" "}
            </Link>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default Signup;
