import React, { Component } from "react";
import "./main.css";
import { Link } from "react-router-dom";
import Header from "./header.jsx";
import Contact from "./contacts.jsx";
import Mine from "./images/mine.jpg";
import Suggest from "./suggestion.jsx";
import Request from "./Request.jsx";
import Feed from "./questionFeed.jsx";
import Notify from "./notification.jsx";
class Home extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="home_whole">
          <Header />

          <div className="Home_container">
            <div className="connect">
              <div className="home_title"> Connections</div>
              <div className="connect_cit">
                <div>Contact </div>
                <div>Suggestion</div>
                <div>Request</div>
              </div>
              <div>
                <Contact />
              </div>
            </div>
            <div className="Que">
              <div className="search_holder">
                <input
                  type="search"
                  className="search_input2"
                  placeholder="Ask a question..."
                />
              </div>
              <div className="Que_sub">
                <Feed />
              </div>
            </div>
            <div className="Noti">
              <div className="home_title">Notification</div>
              <div>
                <Notify />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
