import React, { Component } from "react";
import "./main.css";
import { Link } from "react-router-dom";
import Header from "./header.jsx";
class Task extends Component {
  state = {
    changeme: false,
  };
  render() {
    return (
      <React.Fragment>
        <Header />
      </React.Fragment>
    );
  }
}

export default Task;
