import React, { Component } from "react";
import "./main.css";
import { Link } from "react-router-dom";
import Logo from "./images/logo.png";
import Mine from "./images/mine.jpg";

class Contact extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="contact">
          <div className="contact_container">
            <div className="c_pic">
              <img src={Mine} className="contact_pic" />
            </div>
            <div className="c_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">I will be back soon dear</p>
            </div>
            <div className="c_time">
              <p className="time">4:30pm</p>
              <p className="alert">1</p>
            </div>
          </div>

          <div className="contact_container">
            <div className="c_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="c_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">I will be back soon dear</p>
            </div>
            <div className="c_time">
              <p className="time">4:30pm</p>
              <p className="alert">1</p>
            </div>
          </div>

          <div className="contact_container">
            <div className="c_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="c_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">I will be back soon dear</p>
            </div>
            <div className="c_time">
              <p className="time">4:30pm</p>
              <p className="alert">1</p>
            </div>
          </div>

          <div className="contact_container">
            <div className="c_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="c_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">I will be back soon dear</p>
            </div>
            <div className="c_time">
              <p className="time">4:30pm</p>
              <p className="alert">1</p>
            </div>
          </div>

          <div className="contact_container">
            <div className="c_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="c_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">I will be back soon dear</p>
            </div>
            <div className="c_time">
              <p className="time">4:30pm</p>
              <p className="alert">1</p>
            </div>
          </div>

          <div className="contact_container">
            <div className="c_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="c_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">I will be back soon dear</p>
            </div>
            <div className="c_time">
              <p className="time">4:30pm</p>
              <p className="alert">1</p>
            </div>
          </div>

          <div className="contact_container">
            <div className="c_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="c_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">I will be back soon dear</p>
            </div>
            <div className="c_time">
              <p className="time">4:30pm</p>
              <p className="alert">1</p>
            </div>
          </div>

          <div className="contact_container">
            <div className="c_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="c_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">I will be back soon dear</p>
            </div>
            <div className="c_time">
              <p className="time">4:30pm</p>
              <p className="alert">1</p>
            </div>
          </div>

          <div className="contact_container">
            <div className="c_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="c_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">I will be back soon dear</p>
            </div>
            <div className="c_time">
              <p className="time">4:30pm</p>
              <p className="alert">1</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contact;
