import React, { Component } from "react";
import Mine from "./images/mine.jpg";
import Logo from "./images/logo.png";

class Notify extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="notify">
          <div className="noti_container1">
            <div className="n_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="n_text">
              {" "}
              <p> Exam Alert</p>
              <p className="c_m">Your exams starts in 1 week</p>
            </div>
            <div className="n_time1">
              <p>4:30pm</p>
            </div>
          </div>

          <div className="noti_container1">
            <div className="n_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="n_text">
              {" "}
              <p> Abdul Mohammed</p>
              <p className="c_m">Recently Made a post</p>
            </div>
            <div className="time2">
              <p className="nb_time">20 hours ago</p>
            </div>
          </div>

          <div className="noti_container1">
            <div className="n_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="n_text">
              {" "}
              <p> Abdul Mohammed</p>
              <p className="c_m">Commented on your post</p>
            </div>
            <div className="time2">
              <p className="nb_time">2 weeks ago</p>
            </div>
          </div>

          <div className="noti_container2">
            <div className="n_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="n_text">
              {" "}
              <p> Abdul Mohammed</p>
              <p className="c_m">Shared your post</p>
            </div>
            <div className="time2">
              <p className="nb_time">2 weeks ago</p>
            </div>
          </div>

          <div className="noti_container2">
            <div className="n_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="n_text">
              {" "}
              <p> Abdul Mohammed</p>
              <p className="c_m">Shared a post</p>
            </div>
            <div className="time2">
              <p className="nb_time">2 weeks ago</p>
            </div>
          </div>

          <div className="noti_container2">
            <div className="n_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="n_text">
              {" "}
              <p> Abdul Mohammed</p>
              <p className="c_m">Liked your post</p>
            </div>
            <div className="time2">
              <p className="nb_time">2 weeks ago</p>
            </div>
          </div>

          <div className="noti_container2">
            <div className="n_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="n_text">
              {" "}
              <p> Abdul Mohammed</p>
              <p className="c_m">Voted your answer</p>
            </div>
            <div className="time2">
              <p className="nb_time">2 weeks ago</p>
            </div>
          </div>

          <div className="noti_container1">
            <div className="n_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="n_text">
              {" "}
              <p> Exam Alert</p>
              <p className="c_m">Your exams starts in 1 week</p>
            </div>
            <div className="n_time1">
              <p>4:30pm</p>
            </div>
          </div>

          <div className="noti_container1">
            <div className="n_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="n_text">
              {" "}
              <p> Abdul Mohammed</p>
              <p className="c_m">Recently Made a post</p>
            </div>
            <div className="time2">
              <p className="nb_time">20 hours ago</p>
            </div>
          </div>

          <div className="noti_container1">
            <div className="n_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="n_text">
              {" "}
              <p> Abdul Mohammed</p>
              <p className="c_m">Commented on your post</p>
            </div>
            <div className="time2">
              <p className="nb_time">2 weeks ago</p>
            </div>
          </div>

          <div className="noti_container2">
            <div className="n_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="n_text">
              {" "}
              <p> Abdul Mohammed</p>
              <p className="c_m">Shared your post</p>
            </div>
            <div className="time2">
              <p className="nb_time">2 weeks ago</p>
            </div>
          </div>

          <div className="noti_container2">
            <div className="n_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="n_text">
              {" "}
              <p> Abdul Mohammed</p>
              <p className="c_m">Shared a post</p>
            </div>
            <div className="time2">
              <p className="nb_time">2 weeks ago</p>
            </div>
          </div>

          <div className="noti_container2">
            <div className="n_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="n_text">
              {" "}
              <p> Abdul Mohammed</p>
              <p className="c_m">Liked your post</p>
            </div>
            <div className="time2">
              <p className="nb_time">2 weeks ago</p>
            </div>
          </div>

          <div className="noti_container2">
            <div className="n_pic">
              <img src={Logo} className="Home_logo" />
            </div>
            <div className="n_text">
              {" "}
              <p> Abdul Mohammed</p>
              <p className="c_m">Voted your answer</p>
            </div>
            <div className="time2">
              <p className="nb_time">2 weeks ago</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Notify;
