import React, { Component } from "react";
import "./main.css";
import { Link } from "react-router-dom";
import {
  AccountCircle,
  DesktopWindowsOutlined,
  Home,
  PlayArrowOutlined,
  TaskAlt,
} from "@mui/icons-material";
import Logo from "./images/logo.png";

class Header extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  highlight = () => {};
  render() {
    let hi = this.props.changeme ? "wm" : "cm";

    return (
      <React.Fragment>
        <div className="Header_container">
          <div className="Home_logocont">
            {" "}
            <img src={Logo} className="Home_logo" />
          </div>
          <div className="nav_holder">
            <div className="in_holder">
              <Link to="/Home" identity="one">
                {" "}
                <div title="Home" className={hi}>
                  {" "}
                  <Home style={{ marginBottom: 0, fontSize: 25 }} />
                </div>
              </Link>
              <Link to="/Task" identity="two">
                {" "}
                <div title="Task" className="nav">
                  {" "}
                  <TaskAlt style={{ marginBottom: 0, fontSize: 25 }} />
                </div>
              </Link>
              <Link to="/Learn" identity="three">
                {" "}
                <div title="Learn" className="nav">
                  {" "}
                  <DesktopWindowsOutlined
                    style={{ marginBottom: 0, fontSize: 25 }}
                  />
                </div>
              </Link>
              <Link to="/Video" identity="four">
                {" "}
                <div title="Video" className="nav">
                  {" "}
                  <PlayArrowOutlined
                    style={{ marginBottom: 0, fontSize: 33 }}
                  />
                </div>
              </Link>
              <Link to="/Profile" identity="five">
                {" "}
                <div title="Profile" className="nav">
                  {" "}
                  <AccountCircle style={{ marginBottom: 0, fontSize: 25 }} />
                </div>
              </Link>
            </div>
          </div>
          <div className="searchcont">
            <input
              type="search"
              placeholder="search... "
              className="search_input"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Header;
