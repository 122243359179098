import React, { Component } from "react";
import "./main.css";
import { Link } from "react-router-dom";
import Mine from "./images/mine.jpg";
import Logo from "./images/logo.png";
import {
  Share,
  Mode,
  FavoriteBorder,
  Sync,
  KeyboardVoice,
  AttachFile,
  EmojiEmotions,
  EmojiEmotionsOutlined,
} from "@mui/icons-material";
import { Avatar } from "@mui/material";
class Feed extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="feed_head">
          <img src={Mine} className="user_pic" />
          <div className="feed_name"> Chuks Chris</div>
        </div>
        <div className="feed_content">
          {" "}
          What is the name of the person that designed the Nigerian flag and in
          what year
        </div>
        <div>
          {" "}
          <img src={Mine} className="feed_pic" />
        </div>

        <div className="reaction">
          <Share style={{ fontSize: 23 }} />
          <Mode style={{ fontSize: 23 }} />
          <FavoriteBorder style={{ fontSize: 23 }} />
          <Sync style={{ fontSize: 23 }} />
        </div>
        <div className="comment_cont">
          <Avatar src={Mine} />
          <EmojiEmotionsOutlined style={{ marginBottom: 0 }} />
          <input
            type="text"
            placeholder="Write a comment..."
            className="comment_input"
          />
          <AttachFile style={{ marginBottom: 0 }} />
          <KeyboardVoice style={{ marginBottom: 0, fontSize: 27 }} />
        </div>
        <div className="comment_cont">
          <Avatar src={Mine} />
          <div className="vote">
            <div className="vote_amt">2 Votes</div>
          </div>
          <input
            type="text"
            placeholder="Write a comment..."
            className="comment_input"
          />
          <div className="reply">
            {" "}
            <div className="vote_amt">
              5 <br />
              Reply
            </div>
          </div>{" "}
        </div>
      </React.Fragment>
    );
  }
}

export default Feed;
