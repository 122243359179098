import React, { Component } from "react";
import "./main.css";
import { Link } from "react-router-dom";
import Mine from "./images/mine.jpg";

class Request extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="contact">
          <div className="suggest_container">
            <div className="c_pic">
              {" "}
              <img src={Mine} className="contact_pic" />
            </div>
            <div className="s_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">Mentor</p>
            </div>
            <div className="s_incre">
              <div className="s_add">Accept</div>
              <div className="s_reject">Reject</div>
            </div>
          </div>

          <div className="suggest_container">
            <div className="c_pic">
              {" "}
              <img src={Mine} className="contact_pic" />
            </div>
            <div className="s_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">Mentor</p>
            </div>
            <div className="s_incre">
              <div className="s_add">Accept</div>
              <div className="s_reject">Reject</div>
            </div>
          </div>

          <div className="suggest_container">
            <div className="c_pic">
              {" "}
              <img src={Mine} className="contact_pic" />
            </div>
            <div className="s_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">Mentor</p>
            </div>
            <div className="s_incre">
              <div className="s_add">Accept</div>
              <div className="s_reject">Reject</div>
            </div>
          </div>

          <div className="suggest_container">
            <div className="c_pic">
              {" "}
              <img src={Mine} className="contact_pic" />
            </div>
            <div className="s_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">Mentor</p>
            </div>
            <div className="s_incre">
              <div className="s_add">Accept</div>
              <div className="s_reject">Reject</div>
            </div>
          </div>

          <div className="suggest_container">
            <div className="c_pic">
              {" "}
              <img src={Mine} className="contact_pic" />
            </div>
            <div className="s_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">Mentor</p>
            </div>
            <div className="s_incre">
              <div className="s_add">Accept</div>
              <div className="s_reject">Reject</div>
            </div>
          </div>

          <div className="suggest_container">
            <div className="c_pic">
              {" "}
              <img src={Mine} className="contact_pic" />
            </div>
            <div className="s_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">Mentor</p>
            </div>
            <div className="s_incre">
              <div className="s_add">Accept</div>
              <div className="s_reject">Reject</div>
            </div>
          </div>

          <div className="suggest_container">
            <div className="c_pic">
              {" "}
              <img src={Mine} className="contact_pic" />
            </div>
            <div className="s_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">Mentor</p>
            </div>
            <div className="s_incre">
              <div className="s_add">Accept</div>
              <div className="s_reject">Reject</div>
            </div>
          </div>

          <div className="suggest_container">
            <div className="c_pic">
              {" "}
              <img src={Mine} className="contact_pic" />
            </div>
            <div className="s_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">Mentor</p>
            </div>
            <div className="s_incre">
              <div className="s_add">Accept</div>
              <div className="s_reject">Reject</div>
            </div>
          </div>

          <div className="suggest_container">
            <div className="c_pic">
              {" "}
              <img src={Mine} className="contact_pic" />
            </div>
            <div className="s_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">Mentor</p>
            </div>
            <div className="s_incre">
              <div className="s_add">Accept</div>
              <div className="s_reject">Reject</div>
            </div>
          </div>

          <div className="suggest_container">
            <div className="c_pic">
              {" "}
              <img src={Mine} className="contact_pic" />
            </div>
            <div className="s_text">
              {" "}
              <p> Charles Ugwu</p>
              <p className="c_m">Mentor</p>
            </div>
            <div className="s_incre">
              <div className="s_add">Accept</div>
              <div className="s_reject">Reject</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Request;
