import React, { Component } from "react";
import "./signin.css";
import "./main.css";
import Forgotp from "./images/forgot_pass.png";
class forgot extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="maincontainer">
          <div className="f_img_cont">
            <img src={Forgotp} className="f_img" />
          </div>
          <p className="title">Forgot your password</p>
          <p className="instruct">
            A password reset link will be sent to your email address
            <br />
            Enter your email address below
          </p>

          <form className="form_content">
            {" "}
            <input type="Email" placeholder="Email..." className="form_input" />
            <input type="button" value="Submit" className="submitbutton" />
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default forgot;
